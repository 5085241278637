import fetch from "@/utils/fetch";
import {Report} from "@/@type/report";

export default class ReportApi {
  async save(data : Report) {
    return await fetch.post("/api/report/save", data)
  }
  async list(filter: any) {
    return await fetch.post("/api/report/list", filter)
  }
  async delete(uid : string) {
    return await fetch.post("/api/report/delete?uid=" + uid, null)
  }
  async restore(uid : string) {
    return await fetch.post("/api/report/restore?uid=" + uid, null)
  }
  async getSettings() {
    return await fetch.get("/api/report/settings")
  }
  async getReportSettings(uid: string) {
    return await fetch.get("/api/report/settings/"+uid)
  }
  async saveSettings(uid: string, settings: string[]) {
    return await fetch.post("/api/report/settings/save?uid="+uid, settings)
  }
}

import tokens from "@/service/tokens";
import {Service} from "vue-typedi";
import EmployeeApi from "./api/employeeApi";
import {Employee} from "@/@type/org.type";

const api = new EmployeeApi();

export default interface EmployeeService {
    getList (filter: Employee) : Promise<Employee[]>
    delete (uid: string): Promise<boolean>
    save (data: Employee): Promise<string>
    download(): Promise<any>
    template(): Promise<any>
    upload(formData: any): Promise<any>
}

@Service(tokens.EMPLOYEE_SERVICE)
export default class EmployeeServiceImpl implements EmployeeService {
    async delete(uid: string): Promise<boolean> {
        return await api.delete(uid);
    }

    async getList(filter: Employee): Promise<Employee[]> {
        return await api.getList(filter);
    }

    async save(data: Employee): Promise<string> {
        return await api.save(data);
    }

    async download(): Promise<any> {
      return await api.download();
    }

    async template(): Promise<any> {
      return await api.template()
    }

    async upload(file: File): Promise<any>{
      return await api.upload(file);
    }
}


import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class Loading extends Vue {

  get isLoading() {
    return Vue.prototype.$myStore.base.isLoading
  }
}

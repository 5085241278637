import fetch from "@/utils/fetch";

export default class CashApi {
  public async getList(filter: any) {
    return await fetch.post("/api/cash/list", filter)
  }

  public async delete(uid: string) {
    return await fetch.post("/api/cash/delete?uid="+uid, null)
  }

  public async save(data: any) {
    return await fetch.post("/api/cash/save", data)
  }
}


import {Component, Vue, Watch} from 'vue-property-decorator';
import AppLoader from '@/layouts/AppLoader.vue';
import MainLayout from "@/layouts/MainLayout.vue";
import EmptyLayoutt from "@/layouts/EmptyLayoutt.vue";
import ErrorLayout from "@/layouts/ErrorLayout.vue";

import {useStore} from 'vuex-simple';
import {MyStore} from '@/store/store';

@Component({
  components: { AppLoader, MainLayout, EmptyLayoutt, ErrorLayout },
})

export default class App extends Vue {
  public myStore: MyStore = useStore(this.$store);
  inited: undefined | boolean = false

  get layout() : string{
    return ((this.$route.meta && this.$route.meta.layout) || "empty") + "-layout"
  }

  @Watch('layout')
  layoutWatcher(){
    this.init()
  }

  init(): void {
    this.inited = true
    if(this.myStore.app.inited){
      this.inited = this.$route.meta && this.$route.meta.layout !== 'error';
    }else this.inited = false;
  }
}


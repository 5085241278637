

import {Component, Vue} from "vue-property-decorator";

@Component
export default class TheBrand extends Vue {

  onHome(){
    return this.$router.push('/home')
  }
}

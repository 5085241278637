import cookie from "@/utils/clientSession/cookie";
// import sysname from "@/filters/sysname";

export default {
    cookie() : string {
        const phone: string = location.pathname.substring(1).split('/')[1];
        cookie.set('phone', phone)
        const value: string = location.pathname.substring(1).split('/')[2];
        cookie.set('value', value)
        let catalog: string = ""
        catalog = location.pathname.substring(1).split('/')[3];
        cookie.set('sysname', catalog)
        return "/home"
    }
}

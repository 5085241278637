import MetricApi from "@/service/api/metricApi";
import {Service} from "vue-typedi";
import tokens from "@/service/tokens";

const api = new MetricApi()

export default interface MetricService {
  getListAll () : Promise<any[]>
}

@Service(tokens.METRIC_SERVICE)
export default class MetricServiceImpl implements MetricService {
  async getListAll(): Promise<any[]> {
    return await api.getListAll();
  }
}

import {Inject, Injectable} from "vue-typedi";
import ReportService from "@/service/report/report.service";
import {Action, Getter, Mutation, State} from "vuex-simple";
import tokens from "@/service/tokens";

@Injectable()
export class ReportsCache {

  @Inject(tokens.REPORT_SERVICE)
  private reportService: ReportService;

  @State()
  private items: any[];

  @Mutation()
  public mutate(nb: any[]) {
    this.items = nb;
  }

  @Action()
  public async load(): Promise<any>{
    console.log("load: report-settings")
    const items = await this.reportService.getSettings()

    this.mutate(items)
  }

  @Getter()
  public get getItems() {
    return this.items
  }
}

import tokens from "@/service/tokens";
import {Service} from "vue-typedi";
import {DirectionApi} from "@/service/api/directionApi";
import {Pay} from "@/@type/pay.type";

const api = new DirectionApi();

export default interface DirectionService {
  getPage (page: number, filter: any) : Promise<any[]>
  getList (filter: any) : Promise<any[]>
  getListByPerson(personUid: string): Promise<any[]>
  delete (id: string): Promise<boolean>
  cancel (id: string): Promise<boolean>
  save (data: any): Promise<string>
  edit (data: any): Promise<string>
  logs(id: number): Promise<any[]>
  pay(data: Pay): Promise<boolean>
  getCardTypes(): Promise<string[]>
}

@Service(tokens.DIRECTION_SERVICE)
export default class DirectionServiceImpl implements DirectionService {
  async delete(id: string): Promise<boolean> {
    return await api.delete(id);
  }

  async cancel(id: string): Promise<boolean> {
    return await api.cancel(id);
  }

  async getPage(page: number, filter: any): Promise<any[]> {
    return await api.getPage(page,filter);
  }

  async getList(filter: any): Promise<any[]> {
    return await api.getList(filter);
  }

  async getListByPerson(personUid: string): Promise<any[]> {
    return await api.getListByPerson(personUid);
  }

  async save(data: any): Promise<string> {
    return await api.save(data);
  }

  async edit(data: any): Promise<string> {
    return await api.edit(data);
  }

  async pay(data: Pay): Promise<boolean> {
    return await api.pay(data)
  }

  async logs(id: number): Promise<any[]> {
    return await api.getLogs(id);
  }

  async getCardTypes(): Promise<string[]> {
    return await api.getCardTypes();
  }
}

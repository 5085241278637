import {Service} from "vue-typedi";
import tokens from "@/service/tokens";
import SrcApi from "@/service/api/srcApi";
import {CompanyInfo} from "@/@type/company.info";
import {User} from "@/@type/user.type";

const srcApi = new SrcApi();

export default interface SrcService {
    getUsers () : Promise<any>;
    getCompany(uid: string) : Promise<CompanyInfo>;
    getUser() : Promise<User>;
}

@Service(tokens.SRC_SERVICE)
export default class SrcServiceImpl implements SrcService{
    async getUsers() :  Promise<any>{
        return srcApi.users();
    }

    async getCompany(uid: string): Promise<CompanyInfo> {
      return await srcApi.getCompany(uid);
    }

  async getUser(): Promise<User> {
    return await srcApi.getUser();
  }
}

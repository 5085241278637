import auth from "@/utils/clientSession/auth";


export default () => {
    return new Promise((resolve : any) => {
        let catalog : string = ""
        catalog = location.pathname.substring(1).split('/')[0];

        if (process.env.VUE_APP_AUTH_METHOD === 'COOKIE' && catalog === 'cookie') {
            const redirectUrl = auth.cookie()
            return location.replace(redirectUrl)
        }
        if (catalog === "404") return resolve()



        // if (!catalog) {
        // if (catalogs_.length > 0) {
        //     session.clear()
        //     session.set(catalogs_[0])
        //     return location.replace(location.origin.concat("/", catalogs_[0].sysname))
        // }
        // }

        // const exist = catalogs_.filter(c => c.sysname === catalog)
        // if (exist.length > 0) {
        //     if (!session.validate(catalog)) {
        //         session.clear()
        //         session.set(catalog)
        //         return location.replace(location.origin.concat("/", catalog))
        //     }
        // } else {
        //     return location.replace(location.origin.concat("/404"))
        // }
        resolve(catalog)
    })
}

import fetch from "@/utils/fetch";
import {ShiftFilter} from "@/@type/shift.type";

export default class ShiftApi {

  public async page(filter: ShiftFilter, page: number = 0) {
    const sortBy = filter.sortBy ? filter.sortBy : 'openDateTime'
    const sortType = filter.sortType ? filter.sortType : 'DESC'
    const sort = '&sort='+sortBy+','+sortType;
    return await fetch.post("/api/shift/page?page="+page+sort, filter)
  }

  public async info() {
    return await fetch.get("/api/shift/info")
  }

  public async close() {
    return await fetch.post("/api/shift/close", null)
  }

  public async closeByUid(uid: string) {
    return await fetch.post("/api/shift/close/"+uid, null)
  }
}

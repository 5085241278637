import OfferApi from "@/service/api/offerApi";
import {Service} from "vue-typedi";
import tokens from "@/service/tokens";

const api = new OfferApi();

export default interface OfferService{
  getList (filter: any) : Promise<any[]>
  delete (uid: string): Promise<boolean>
  restore (uid: string): Promise<boolean>
  save (data: any): Promise<string>
  download(): Promise<any>
  template(): Promise<any>
  upload(formData: any): Promise<any>
}

@Service(tokens.OFFER_SERVICE)
export default class OfferServiceImpl implements OfferService {

  async getList(filter: any) : Promise<any[]>{
    return await api.getList(filter)
  }

  async delete(uid: string): Promise<boolean>{
    return await api.delete(uid)
  }

  async restore(uid: string): Promise<boolean>{
    return await api.restore(uid)
  }

  async save(data: string): Promise<string>{
    return await api.save(data)
  }

  async download(): Promise<any> {
    return await api.download();
  }

  async template(): Promise<any> {
    return await api.template()
  }

  async upload(file: File): Promise<any>{
    return await api.upload(file);
  }
}

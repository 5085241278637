import DepartmentApi from "@/service/api/departmentApi";
import tokens from "@/service/tokens";
import {Service} from "vue-typedi";
import {Department, DepartmentFilter} from "@/@type/org.type";

const api = new DepartmentApi();

export default interface DepartmentService {
  getList (filter: DepartmentFilter) : Promise<Department[]>
  delete (uid: string): Promise<boolean>
  save (data: Department): Promise<string>
}

@Service(tokens.ORGANIZATION_SERVICE)
export default class DepartmentServiceImpl implements DepartmentService {

  async getList(filter: DepartmentFilter): Promise<Department[]> {
    return await api.getList(filter);
  }

  async save(data: Department): Promise<string> {
    return await api.save(data);
  }

  async delete(uid: string): Promise<boolean> {
    return await api.delete(uid);
  }
}



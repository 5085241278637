import { render, staticRenderFns } from "./ShiftDialog.vue?vue&type=template&id=0997faf6&scoped=true&"
import script from "./ShiftDialog.vue?vue&type=script&lang=ts&"
export * from "./ShiftDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0997faf6",
  null
  
)

export default component.exports
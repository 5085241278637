import {Action, Getter, Mutation, State} from 'vuex-simple';
import {Inject, Injectable} from "vue-typedi";
import tokens from "@/service/tokens";
import SrcService from "@/service/srcService";
import CashUserService from "@/service/cashUserService";

@Injectable()
export class Users {
    @Inject(tokens.SRC_SERVICE)
    public srcService: SrcService

    @Inject(tokens.CASH_USER_SERVICE)
    private cashUserService: CashUserService

    @State()
    public users: any;

    @Mutation()
    public mutateUsers(nb: any) {
        this.users = nb;
    }

    @Action()
    public async setUsers(): Promise<any>{
        const users = await this.srcService.getUsers()
        this.mutateUsers(users)
    }

    @Action()
    public async setCash(data: any){
      const uid = await this.cashUserService.save(data)
      if(uid){
        for(const i in this.users){
          if(this.users[i].phone == data.sysname){
            const cash = await this.cashUserService.get(this.users[i].phone)
            this.users[i].cash = cash.uid != null ? cash : null
            break
          }
        }
      }
    }

    @Action()
    public async deleteCash(phone: string){
      const res = await this.cashUserService.delete(phone)
      if(res){
        for(const i in this.users){
          if(this.users[i].phone == phone){
            this.users[i].cash = null
            break
          }
        }
      }
    }

    @Getter()
    public get getUsers() {
      return this.users
    }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import vuetify from '@/plugins/vuetify'
import "./plugins/utils"
import "./plugins/components"
import 'reflect-metadata'

Vue.config.productionTip = false

import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expire: '7d'})

import VueTypedi from 'vue-typedi'
Vue.use(VueTypedi)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

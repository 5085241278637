
import TheBrand from '@/components/app/TheBrand.vue';
import {Component, Vue} from "vue-property-decorator";
import session from "@/utils/clientSession/session";
import ShiftDialog from "@/components/ShiftDialog.vue";
import cookie from "@/utils/clientSession/cookie";

@Component({
  components: { TheBrand, ShiftDialog },
})

export default class TheNav extends Vue {

  $refs: {
    shiftDialog: ShiftDialog
  }

  get catalogs() : any{
    let items = Vue.prototype.$myStore.catalog.catalogs
    return items && items.length ? items : [{catalog: "empty!"}]
  }

  get cash(): any {
    const cash = Vue.prototype.$myStore.cashUser.get
    return cash != undefined && cash.uid != null ? cash : null
  }

  get currentCatalog() : string{

    let catalog = Vue.prototype.$myStore.catalog.currentCatalog
    return catalog ? catalog.company.shortTitle : "empty!"
  }

  onShift(){
    this.$refs.shiftDialog.show()
  }

  setCurrentCatalog(catalog: any): void{
    cookie.set('sysname', catalog.catalog)
    Vue.prototype.$myStore.catalog.setCurrentCatalog(catalog)
    location.reload()
  }

  logout() : void{
    session.logout()
  }
}

Vue.component("the-nav", TheNav)


import {Service} from "vue-typedi";
import tokens from "@/service/tokens";
import AccessesApi from "@/service/api/accessesApi";
import cookie from "@/utils/clientSession/cookie"

const accessesApi = new AccessesApi();

export default interface AccessesService {
  getCurrentUserAccesses (currentCatalog: any) : Promise<any[]>;
  getAccessesByPhone (phone: string) : Promise<any>;
  getAccesses () : Promise<string[]>;
  saveAccesses(phone : string, accesses : string[]) : Promise<any>;
}

@Service(tokens.ACCESSES_SERVICE)
export default class AccessServiceImpl implements AccessesService{

  async getCurrentUserAccesses(currentCatalog: any) : Promise<any[]>{
    if(!currentCatalog) return []
    if(currentCatalog.role === 'ADMIN'){
      return await accessesApi.items()
    }else{
      return await accessesApi.rights(cookie.get("phone"))
    }
  }

  async getAccessesByPhone(phone: string) : Promise<any>{
    if(!phone) return null
      return await accessesApi.rights(phone)
  }

  async getAccesses() : Promise<string[]>{
    return await accessesApi.items();
  }

  async saveAccesses(phone : string, accesses : string[]) : Promise<any>{
    return await accessesApi.save(phone, accesses)
  }
}

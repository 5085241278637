import fetch from "@/utils/fetch";

export default class OfferApi {

  public async getList(filter: any) {
    return await fetch.post("/api/offer/list", filter)
  }

  public async delete(uid: string) {
    return await fetch.post("/api/offer/delete?uid="+uid, null)
  }

  public async restore(uid: string) {
    return await fetch.post("/api/offer/restore?uid="+uid, null)
  }

  public async save(data: any) {
    return await fetch.post("/api/offer/save", data)
  }

  public async download(){
    return await fetch.get("/api/offer/file/download")
  }

  public async template(){
    return await fetch.get("/api/offer/file/template")
  }

  public async upload(file: File){
    return await fetch.postFile("/api/offer/file/upload", file)
  }

}

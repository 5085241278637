// import store from '@/store'
// import {qz_localize} from "@/localize/qz";
import { ru_localize } from '@/locolize/ru';
//
export default function localize(key: string): string | undefined {
    if (!key) return;
    return ru_localize.get(key) ? ru_localize.get(key) : key
//     switch (store.getters["localize/getLocalize"]){
//         case 'ru' : return ru_localize.get(key) ? ru_localize.get(key) : key
//         case 'qz' : return qz_localize.get(key) ? qz_localize.get(key) : key
//     }
}

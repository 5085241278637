
import {Component, Vue, Watch} from "vue-property-decorator";
import {Inject} from "vue-typedi";
import tokens from "@/service/tokens";
import NavigationService from "@/service/navigationService";

@Component
export default class TheNavDrawer extends Vue{
  @Inject(tokens.NAVIGATION_SERVICE)
  private navigationService: NavigationService
  index : number | null = 0
  selectedItem: any = null

  groups: any[] = []
  isMounted: boolean = false

  items: any[] = []


  @Watch('index')
  indexWatcher(n: number, o: number){
    if(n || n === 0) this.selectedItem = n
  }

  isActive(index : number) : boolean{
    return index === this.selectedItem;
  }

  openShow(group: any) : void{
    group.show = !group.show
  }

  async mounted(){
    this.items = await this.navigationService.getNavigationItemsByCurrentUser()
    this.items = this.items.map((x) => ({ ...x, show: false }))
  }
}

Vue.component("the-nav-drawer", TheNavDrawer)


import {Inject, Injectable} from "vue-typedi";
import tokens from "@/service/tokens";
import OfferService from "@/service/offerService";
import {Action, Getter, Mutation, State} from "vuex-simple";
import MetricService from "@/service/metric-service";

@Injectable()
export class Offer {

  @Inject(tokens.OFFER_SERVICE)
  private offerService: OfferService

  @Inject(tokens.METRIC_SERVICE)
  private metricService: MetricService

  @State()
  private items: any[] = [];

  @State()
  private offers: any[] = [];

  @State()
  private parents: any[] = [];

  @State()
  private metrics: any[] = [];

  @Mutation()
  public mutate(nb: any[]) {
    this.items = nb;

    const parents: any[] = [];
    if(this.items != null) {
      for(const item of this.items){
        const parent = parents.find(r => r.uid == item.parent.uid)
        if(!parent) {
          item.parent.childs
          parents.push(item.parent)
        }
      }
      for(const parent of parents){
        parent.children = this.items.filter(r => r.parent.uid == parent.uid)
      }
      this.offers = parents;
    }
  }

  @Mutation()
  public mutateMetrics(nb: any[]) {
    this.metrics = nb;
  }

  @Action()
  public async load(): Promise<any>{
    console.log("load: offer")
    const items = await this.offerService.getList({isParent: true, deleted: false})

    const metrics = await this.metricService.getListAll()
    this.mutate(items != null ? items : [])
    this.mutateMetrics(metrics)
  }

  @Getter()
  public get getItems() {
    return this.items
  }

  @Getter()
  public get getMetrics() {
    return this.metrics
  }

  @Getter()
  public get getOffers(){
    return this.offers
  }
}

import {Inject, Injectable} from "vue-typedi";
import tokens from "@/service/tokens";
import CashUserService from "@/service/cashUserService";
import {Action, Getter, Mutation, State} from "vuex-simple";
import cookie from "@/utils/clientSession/cookie";

@Injectable()
export class CashUser {

  @Inject(tokens.CASH_USER_SERVICE)
  public cashUserService: CashUserService

  @State()
  private item: any;

  @Mutation()
  public mutate(nb: any) {
    this.item = nb;
  }

  @Action()
  public async load(): Promise<any>{
    const item = await this.cashUserService.get(cookie.get('phone'))
    this.mutate(item)
  }

  @Getter()
  public get get() {
    return this.item
  }
}

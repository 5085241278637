
import {Component, Vue, Watch} from 'vue-property-decorator';
import TheNav from '@/components/app/TheNav.vue';
import TheNavDrawer from "@/components/app/TheNavDrawer.vue"
import Loading from "@/layouts/Loading.vue";

@Component({
  components: {Loading, TheNav, TheNavDrawer },
})

export default class MainLayout extends Vue {

  error: any = null
  errorMessage: string = 'oops_something_went_wrong_please_try_again_later'


  get getError() : any{
    return Vue.prototype.$myStore.app.getError
  }

  get getSnackBarIcon() : string{
    switch (this.getError.type){
      case 'success' : return 'mdi-check'
      case 'error' : return 'mdi-close-octagon-outline'
      default : return 'mdi-alert-outline'
    }
  }

  @Watch('getError')
  watchError(n:any, o:any){
    if(JSON.parse(JSON.stringify(n)) !== JSON.parse(JSON.stringify(o))){
      this.error = n
    }
  }

  closeSnackbar(){
    this.clearError()
  }

  clearError(){
    this.error.show = false
    this.error.type = null
    this.error.message = null
  }
}

Vue.component("main-layout", MainLayout)


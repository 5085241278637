import Vue from 'vue'
import localize from "@/utils/localize";
import tokens from "@/service/tokens";
import fetch from "@/utils/fetch";

import index from "@/store/index"
import {useStore} from 'vuex-simple';
import {MyStore} from "@/store/store";
const myStore: MyStore = useStore(index);

const utils = {
    install() {
        Vue.prototype.$localize =  localize
        Vue.prototype.$myStore =  myStore
        Vue.prototype.$tokens =  tokens
        Vue.prototype.$fetch =  fetch
    }
}
Vue.use(utils)

// import { useStore } from 'vuex-simple';
// import store from '@/store/index'
// import { MyStore } from '@/store/store';
// import { FooModule } from '@/store/modules/foo';

// const myStore: MyStore = useStore(store);
// const foo1: FooModule = useModule(store, ['bar', 'foo1']);

// declare module 'vue/types/vue' {
//     interface Vue {
//         $myStore: MyStore
//     }
// }
//js files


// import vm2js from '@/utils/vm2js';
// import treeSearch from '@/utils/treeSearch';
// import dateFormat from "@/utils/dateFormat";
// import dateTimeFormat from "@/utils/dateTimeFormat";
// import localize from "@/utils/localize";
// import appUtils from "@/utils/appUtils";
// import moneyFormat from "@/utils/moneyFormat";
// import testRegex from "@/utils/testRegex";
// //libraries
// import lodash from 'lodash';
// import VueReactiveCookie from "vue-reactive-cookie/dist/vue-reactive-cookie";
// import moment from "moment";
//
// const utils = {
//     install() {
//         Vue.prototype.$fetch = fetch
//         Vue.prototype.$vm2js = vm2js
//         Vue.prototype.$treeSearch = treeSearch
//         Vue.prototype.$dateFormat = dateFormat
//         Vue.prototype.$dateTimeFormat = dateTimeFormat
//         Vue.prototype.$lodash = lodash
//         Vue.prototype.$localize = localize
//         Vue.prototype.$VueReactiveCookie = VueReactiveCookie
//         Vue.prototype.$appUtils = appUtils
//         Vue.prototype.$moneyFormat = moneyFormat
//         Vue.prototype.$moneyFormat = moneyFormat
//         Vue.prototype.$testRegex = testRegex
//         Vue.prototype.$moment = moment
//     }
// }

// Vue.use(utils)





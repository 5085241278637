import {Inject, Injectable} from "vue-typedi";
import tokens from "@/service/tokens";
import DepartmentService from "@/service/departmentService";
import EmployeeService from "@/service/employeeService";
import {Action, Getter, Mutation, State} from "vuex-simple";
import PositionService from "@/service/positionService";
import {Department, DepartmentFilter, Employee, Position, PositionFilter} from "@/@type/org.type";

@Injectable()
export class Organization {

  @Inject(tokens.ORGANIZATION_SERVICE)
  private departmentService: DepartmentService

  @Inject(tokens.EMPLOYEE_SERVICE)
  private employeeService: EmployeeService

  @Inject(tokens.POSITION_SERVICE)
  private positionService: PositionService

  @State()
  private items: Department[];

  @State()
  private positions: Position[];

  @Mutation()
  public mutate(nb: Department[]) {
    this.items = nb;
  }

  @Mutation()
  public mutatePosition(nb: Position[]) {
    this.positions = nb;
  }

  @Action()
  public async load(): Promise<any>{
    const items = await this.departmentService.getList({} as DepartmentFilter) as Department[];
    for (const i in items){
      items[i].children = await this.loadEmployees(items[i]) as Employee[]
      items[i].type = "department"
    }
    this.mutate(items)

    const positions = await this.positionService.getList({} as PositionFilter)
    this.mutatePosition(positions)
  }

  public async loadEmployees(item: Department){
    const employees = await this.employeeService.getList({departmentUid: item.uid} as Employee) as Employee[];
    for(const i in employees){
      employees[i].type = 'employee'
      employees[i].departmentUid = employees[i].department != null ? employees[i].department.uid : ""
      employees[i].positionUid = employees[i].position != null ? employees[i].position.uid : ""
    }

    return employees == null ? [] : employees
  }

  public async saveDep(data: Department){
    if(data.uid != null){
      await this.departmentService.save(data);
    } else {
      const uid = await this.departmentService.save(data);
      data.uid = uid;
      data.type = 'department'
      data.children = [];

      this.items.push(data)
    }

  }

  public async deleteDep(uid: string){
    await this.departmentService.delete(uid);

    for (const i in this.items){
      if(this.items[i].uid == uid){
        this.items.splice(Number(i), 1);
        break;
      }
    }
  }

  public async saveEmp(data: Employee){
    if(data.uid != null){
      let item = new Employee();
      for(const i in this.items){
        for(const j in this.items[i].children){
          if(this.items[i].children[j].uid == data.uid){
            item = this.items[i].children[j];
            break
          }
        }
      }
      const res = await this.employeeService.save(data)
      const oldDepartmentUid = item.department.uid;
      const newDepartmentUid = data.departmentUid;
      if(res && item.departmentUid != oldDepartmentUid){
        for(const i in this.items){
          if(this.items[i].uid == oldDepartmentUid){
            for(const j in this.items[i].children){
              if(this.items[i].children[j].uid == item.uid){
                this.items[i].children.splice(Number(j), 1)
                break;
              }
            }
          }
          if(this.items[i].uid == newDepartmentUid){
            item.department = this.items[i];
            this.items[i].children = await this.loadEmployees(this.items[i])
          }
        }
      }

      const oldPositionUid = item.position.uid;
      const newPositionUid = data.positionUid;
      if(oldPositionUid != newPositionUid){
        for(const i in this.items){
          if(this.items[i].uid == item.departmentUid){
            for(const j in this.items[i].children){
              if(this.items[i].children[j].uid == item.uid){
                this.items[i].children[j].position = this.positions.find(r => r.uid == newPositionUid) as Position
                break
              }
            }
            break
          }
        }
      }
    } else {
      const uid = await this.employeeService.save(data)
      for(const i in this.items){
        if(this.items[i].uid == data.departmentUid){
          data.uid = uid
          data.type = 'employee'
          data.position = this.positions.find(r => r.uid == data.positionUid) as Position
          this.items[i].children.push(data)
        }
      }
    }
  }

  public async deleteEmp(data: Employee){
    await this.employeeService.delete(data.uid);

    for(const i in this.items){
      if(this.items[i].uid == data.departmentUid){
        for(const j in this.items[i].children){
          if(this.items[i].children[j].uid == data.uid){
            this.items[i].children.splice(Number(j), 1);
            break;
          }
        }
      }
    }
  }

  public async addPosition(data: Position){
    const uid = await this.positionService.save(data)
    data.uid = uid
    this.positions.push(data)
  }

  public async getPosition(uid: string){
    return this.positions.find(r => r.uid = uid);
  }

  public async download() {
    return await this.employeeService.download()
  }

  public async template(){
    return await this.employeeService.template()
  }

  @Getter()
  public get getDepartments() {
    return this.items
  }

  @Getter()
  public get getPositions() {
    return this.positions
  }

}

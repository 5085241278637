import ShiftApi from "@/service/api/shiftApi";
import {Service} from "vue-typedi";
import tokens from "@/service/tokens";
import {Shift, ShiftFilter} from "@/@type/shift.type";

const api = new ShiftApi()

export default interface ShiftService {
  close () : Promise<any>
  page (filter: ShiftFilter, page: number): Promise<any>
  info (): Promise<any>
  closeByUid(uid: string): Promise<any>
}

@Service(tokens.SHIFT_SERVICE)
export default class ShiftServiceImpl implements ShiftService {
  async close(): Promise<any> {
    return await api.close();
  }

  async info(): Promise<Shift> {
    return await api.info();
  }

  async page(filter: ShiftFilter, page: number): Promise<any> {
    return await api.page(filter, page);
  }

  async closeByUid(uid: string): Promise<any> {
    return await api.closeByUid(uid);
  }


}

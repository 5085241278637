import tokens from "@/service/tokens";
import {Service} from "vue-typedi";
import PositionApi from "@/service/api/positionApi";
import {Position, PositionFilter} from "@/@type/org.type";

const api = new PositionApi();

export default interface PositionService {
  getList (filter: PositionFilter) : Promise<Position[]>
  delete (uid: string): Promise<boolean>
  save (data: Position): Promise<string>
}

@Service(tokens.POSITION_SERVICE)
export default class PositionServiceImpl implements PositionService {

  async delete(uid: string): Promise<boolean> {
    return await api.delete(uid);
  }

  async getList(filter: PositionFilter): Promise<Position[]> {
    return await api.getList(filter);
  }

  async save(data: Position): Promise<string> {
    return await api.save(data);
  }

}

import fetch from "@/utils/fetch";
import {Department, DepartmentFilter} from "@/@type/org.type";

export default class DepartmentApi {

  public async getList(filter: DepartmentFilter) {
    return await fetch.post("/api/department/list", filter)
  }

  public async delete(uid: string) {
    return await fetch.post("/api/department/delete?uid="+uid, null)
  }

  public async save(data: Department) {
    return await fetch.post("/api/department/save", data)
  }

}

import {Inject, Injectable} from "vue-typedi";
import tokens from "@/service/tokens";
import DirectionService from "@/service/directionService";
import {Action, Getter, Mutation, State} from "vuex-simple";

@Injectable()
export class Direction {

  @Inject(tokens.DIRECTION_SERVICE)
  private directionService: DirectionService;

  @State()
  private cardTypes: string[] = [];

  @Mutation()
  public mutate(nb: string[]) {
    this.cardTypes = nb;
  }

  @Action()
  public async load(): Promise<any>{
    console.log("load: card-types")
    const items = await this.directionService.getCardTypes();
    this.mutate(items != null ? items : [])
  }

  @Getter()
  public get getCardItems() {
    return this.cardTypes
  }


}

import fetch from "@/utils/fetch";
import {Employee, Position} from "@/@type/org.type";

export default class EmployeeApi {

  public async getList(filter: Employee) {
    return await fetch.post("/api/employee/list", filter)
  }

  public async delete(uid: string) {
    return await fetch.post("/api/employee/delete?uid="+uid, null)
  }

  public async save(data: Employee) {
    return await fetch.post("/api/employee/save", data)
  }

  public async download(){
    return await fetch.get("/api/employee/file/download")
  }

  public async template(){
    return await fetch.get("/api/employee/file/template")
  }

  public async upload(file: File){
    return await fetch.postFile("/api/employee/file/upload", file)
  }
}

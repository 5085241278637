import fetch from "@/utils/fetch";
import {Position, PositionFilter} from "@/@type/org.type";

export default class PositionApi {
  public async getList(filter: PositionFilter) {
    return await fetch.post("/api/position/list", filter)
  }

  public async delete(uid: string) {
    return await fetch.post("/api/position/delete?uid="+uid, null)
  }

  public async save(data: Position) {
    return await fetch.post("/api/position/save", data)
  }
}

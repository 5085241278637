import Vue from "vue";

export default {
    set(key: string, value: string) : void{
        localStorage.setItem(key, value);
    },
    get(key: string) : string{
        return <string>localStorage.getItem(key);
    },
    clear(){
        localStorage.clear();
    }
}

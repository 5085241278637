import {Inject, Injectable} from "vue-typedi";
import tokens from "@/service/tokens";
import SrcService from "@/service/srcService";
import {Action, Getter, Mutation, State} from "vuex-simple";
import {CompanyInfo} from "@/@type/company.info";
import cookie from "@/utils/clientSession/cookie";

@Injectable()
export class Company {

  @Inject(tokens.SRC_SERVICE)
  private srcService: SrcService;

  @State()
  public company: CompanyInfo;

  @Mutation()
  public mutate(nb: any) {
    this.company = nb;
  }

  @Action()
  private async load(){
    const catalog = JSON.parse(cookie.get('catalog'));
    const company = await this.srcService.getCompany(catalog.company.uid)
    this.mutate(company);
  }

  @Getter()
  public get get() {
    return this.company
  }

}

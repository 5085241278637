import fetch from "@/utils/fetch";

export default class AccessesApi {
    async items() {
        return await fetch.get("/api/access/items")
    }
    async rights(phone : string) {
        return await fetch.get("/api/access/rights?phone=" + phone)
    }
    async save(phone : string, accesses : string[]) {
      return await fetch.post("/api/access/save?phone=" + phone, accesses)
    }
}


import ReportApi from "@/service/api/report/reportApi";
import {Service} from "vue-typedi";
import tokens from "@/service/tokens";
import {Report} from "@/@type/report";

const api = new ReportApi()

export default interface ReportService {
  getList(filter: any): Promise<Report[]>
  delete (uid: string): Promise<boolean>
  restore (uid: string): Promise<boolean>
  save (data: Report): Promise<string>
  getSettings(): Promise<string[]>
  saveSettings(uid: string, settings: string[]): Promise<boolean>
}

@Service(tokens.REPORT_SERVICE)
export default class ReportServiceImpl implements ReportService {
  async delete(uid: string): Promise<boolean> {
    return await api.delete(uid);
  }

  async getList(filter: any): Promise<Report[]> {
    const items = await api.list(filter)
    for(const i in items){
      const settings = await api.getReportSettings(items[i].uid)
      items[i].settings = settings;
    }
    return items;
  }

  async restore(uid: string): Promise<boolean> {
    return await api.restore(uid);
  }

  async save(data: Report): Promise<string> {
    return await api.save(data);
  }

  async getSettings(): Promise<string[]> {
    return await api.getSettings();
  }

  async saveSettings(uid: string, settings: string[]): Promise<boolean> {
    return await api.saveSettings(uid, settings);
  }



}

import fetch from "@/utils/fetch";

export default class SrcApi {
    async users() {
        return await fetch.get("/api/src/user/list")
    }

    async getCompany(uid: string) {
        return await fetch.get("/api/src/company/get", {uid: uid})
    }

    async getUser(){
      return await fetch.get("/api/src/user/get")
    }
}

export const ru_localize = new Map ([
    //Aa
    ["are_you_sure_you_want_to_delete?", "Вы уверены что хотите удалить?"],
    ["are_you_sure_you_want_to_restore?", "Вы уверены что хотите восстановить?"],
    ["are_you_sure_you_want_to_cancel?", "Вы уверены что хотите отменить?"],
    ["are_you_sure_you_want_to_shift_close?", "Вы уверены что хотите закрыть смену?"],
    ["all_unsaved_data_will_be_lost", "Вы действительно хотите закрыть? <br> Все не сохраненные данные будут утеряны!"],
    ["already_have_an_account?", "Уже есть аккаунт?"],
    ["about_us", "О нас"],
    ["add", "Добавить"],
    ["address", "Адрес"],
    ["add_employee", "Добавить сотрудника"],
    ["accesses", "Роли"],
    ["auto_close", "Автоматическое закрытие смены"],
    ["ACCESS_TITLE", "Управление доступом"],
    ["activity", "Деятельность"],
    ["activate", "Активировать"],
    ["activity_select", "Выбор деятельности"],
    ["attach_cash", "Прикрепить кассу"],
    ["attach_off_cash", "Открепить кассу"],
    ["asc", "По возрастанию"],
    ["add_row", "Добавить строку"],
    ["add_column", "Добавить столбец"],
    ["account", "Счет"],
    ["account_add", "Пополнить счет"],
    ["account_debit", "Снять со счета"],
    ["account_is_lock", "Счет заблокирован времнно"],
    ["not_enough_funds_in_the_account", "Не достаточна средств на счете"],

    //Bb
    ["by_id", "По номеру"],
    ["by_modify_date", "По дате изменения"],

    //Cc
    ["code", "Код"],
    ["cancel", "Отмена"],
    ["CANCEL", "Отменен"],
    ["cash_sum", "Наличные"],
    ["CASH_SUM", "Наличные"],
    ["cashless_sum", "Безналичные"],
    ["CASHLESS_SUM", "Безналичные"],
    ["categories", "Категорий"],
    ["select", "Выбрать"],
    ["create", "Создать"],
    ["confirm", "Подтвердить"],
    ["CREATE", "Создан(а)"],
    ["close_shift", "Закрыть смену"],
    ["cash_register_password", "Пинкод"],
    ["CASH_TITLE", "Кассы"],
    ["cash_types", "Вид кассы"],
    ["cash_selects", "Выбор кассы"],
    ["credentials", "Учетные данные"],
    ["cash_list", "Списокк касс"],
    ["complete", "Завершение"],
    ["contracts", "Договора"],
    ["contract", "Договор"],
    ["contract_num", "№ договора"],
    ["correct_departments", "Правильные подразделения"],
    ["CASHIER", "КАССА"],
    ["card_type", "Банк"],
    ["choose_directions", "Выберите направление"],

    //Dd
    ["description", "Описание"],
    ["detail", "Подробнее"],
    ["department", "Подразделение"],
    ["department_from", "От подразделения"],
    ["doctor", "Врач"],
    ["doctor_from", "От врача"],
    ["document_number", "№ документа"],
    ["document_issue", "Кем выдан"],
    ["document_issue_date", "Дата выпуска"],
    ["direction", "Направление"],
    ["directions", "Направления"],
    ["discount", "Скидка"],
    ["date", "Дата"],
    ["date_pay", "Дата оплаты"],
    ["disable", "Отключить"],
    ["DIRECTION_TITLE", "Направления"],
    ["direct", "прямой"],
    ["download", "Скачать"],
    ["download_contract", "Скачать договор"],
    ["delete", "Удалить"],
    ["desc", "По убыванию"],
    ["date_from", "С"],
    ["date_to", "По"],
    ["DEPARTMENT", "ПОДРАЗДЕЛЕНИЕ"],
    ["DEPARTMENT_FROM", "ОТ ПОДРАЗДЕЛЕНИЯ"],
    ["debit_account", "Списать со счета"],


    //Ee
    ["email", "Эл. почта"],
    ["enter", "Вход"],
    ["edit", "Редактировать"],
    ["exit", "Выход"],
    ["editing", "Редактирование"],
    ["enter_email", "Введите почту"],
    ["errors", "Ошибки"],
    ["except_departments", "Кроме подразделении"],
    ["EMPLOYEE", "ВРАЧ"],
    ["EMPLOYEE_FROM", "ОТ ВРАЧА"],
    ["error_items_sum", "Ошибка! Сумма в направлений не правильная"],

    //Ff
    ["forget_password?", "Забыли пароль?"],
    ["full_name", "Ф.И.О."],
    ["filter", "Фильтр"],
    ["forbidden", "У вас нет доступа! Обратитесь к Администратору"],

    //Gg
    ["generate_contract", "Создать договор"],

    //Hh
    ["home", "Главная"],
    ["history", "История"],

    //Ii
    ["information", "Информация"],
    ["iin", "ИИН"],
    ["income_direct_percent", "Прямой доход (%)"],
    ["income_indirect_percent", "Косвенный доход (%)"],
    ["income", "Доход"],
    ["indirect", "косвенный"],
    ["is_author_edit", "Только автор редактирует"],
    ["IIN_ALREADY_EXISTS", "ИИН уже существует"],

    //Jj

    //Kk
    ["kassa", "Касса"],
    ["KASPI", "Каспи банк"],
    ["HALYK", "Халык банк"],
    ["FORTE", "Форте банк"],
    ["keys_for_data", "Ключи для подстановки данных"],

    //Ll
    ["login", "Войти"],

    //Mm
    ["mandatory_field", "Обязательное поле"],
    ["metric", "Ед. измерения"],

    //Nn
    ["next", "Далее"],
    ["number_direction", "Номер направления"],
    ["no", "Нет"],
    ["no_editing_rights", "Нет прав на редактирования"],

    //Oo
    ["own_cabinet", "Личный кабинет"],
    ["OFFER_TITLE", "Услуги"],
    ["offers", "Услуги"],
    ["offers_all", "Все услуги"],
    ["outcome", "Косвенный доход"],
    ["ORGANIZATION_TITLE", "Организация"],
    ["oops_something_went_wrong_please_try_again_later", "ой, что-то пошло не так, повторите попытку позже"],
    ["OFFER", "УСЛУГИ"],

    //Pp
    ["PATIENT", "ПАЦИЕНТЫ"],
    ["password", "Пароль"],
    ["PERSON_TITLE", "Пациенты"],
    ["paid", "Оплачено"],
    ["pay", "Оплата"],
    ["period", "Период"],
    ["price", "Цена"],
    ["phone", "Телефон"],
    ["PHARMACY_TITLE", "Аптека"],
    ["position", "Должность"],
    ["patient", "Пациент"],
    ["print", "Печать"],
    ["PAY", "Оплачен(а)"],
    ["PAYMENT", "Способ оплаты"],
    ["parent", "Родитель"],
    ["price_with_discount", "Цена со скидкой"],
    ["password_should_be_not_less_than_8_symbols_and_contains_a_digit_and_letter_in_upper_and_lower_case", "Пароль должен быть не менее 8 символов, содержать цифру, букву в нижнем, в верхнем регистре"],
    ["PHONE_ALREADY_EXISTS", "Телефон уже существует"],

    //Qq
    ["quantity", "Кол-во"],

    //Rr
    ["remove", "Удалить"],
    ["refund", "Возврат"],
    ["restore", "Восстановить"],
    ["registration", "Регистрация"],
    ["registration_confirmation_email_has_been_mailed", "Письмо с подтверждением регистрации было отправлено на почту"],
    ["REPORT_TITLE", "Отчеты"],
    ["required", "Поле, обязательное для заполнения"],
    ["re_pay", "Обновить данные из ОФД"],
    ["report", "Отчет"],
    ["refresh", "Обновить"],

    //Ss
    ["save", "Сохранить"],
    ["sale", "Продажа"],
    ["success", "Успешно"],
    ["status", "Статус"],
    ["settings", "Настройки"],
    ["sign_in", "Войти"],
    ["share", "Поделиться"],
    ["select_file", "Выберите файл"],
    ["search", "Поиск"],
    ["search_direction", "Поиск по направлению"],
    ["shift_open", "Смену открыл"],
    ["shift_close", "Смену закрыл"],
    ["shift_open_date", "Дата открыта"],
    ["shift_close_date", "Дата закрыта"],
    ["SHIFT_TITLE", "Итоги продаж"],
    ["SETTINGS_TITLE", "Настройки"],
    ["sort_by", "Сортировать по"],
    ["sort_type", "Тип сортировки"],
    ["SALES", "Продажа"],
    ["SALES_REFUNDS", "Возврат"],
    ["SETTINGS_REPORT_TITLE", "Отчет"],
    ["SETTINGS_TEMPLATE_TITLE", "Шаблон"],
    ["start_date", "Договор с"],
    ["finish_date", "по"],
    ["from_date", "Срок с"],
    ["to_date", "по"],
    ["SETTINGS_TEMPLATE_TITLE", "Шаблон"],

    //Tt
    ["title", "Наименование"],
    ["try_free", "Попробуйте бесплатно"],
    ["total_sum", "Сумма"],
    ["total_sum_text", "Сумма прописью"],
    ["total", "Итого"],
    ["template", "Шаблон"],
    ["transaction", "Номер транзакций"],

    //Uu
    ["upload", "Загрузить"],
    ["upload_result", "Загружено: "],
    ["upload_total", "Всего: "],
    ["users", "Пользователи"],
    ["UPDATE", "Изменил(а)"],
    ["username", "Пользлватель"],
    ["undefined", "Не указан"],
    //Vv

    //Ww
    ["warning", "Предупреждение"],

    //Xx

    //Yy
    ["yes", "Да"]

    //Zz

])

import {Mutation, State} from "vuex-simple";
import {Injectable} from "vue-typedi";

@Injectable()
export class BaseModule {

  @State()
  public isLoading: boolean = false;

  @Mutation()
  setLoadingStatus(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}

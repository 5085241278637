import tokens from "@/service/tokens";
import {Service} from "vue-typedi";
import CashApi from "@/service/api/cashApi";

const api = new CashApi()


export default interface CashService {
  getList (filter: any) : Promise<any[]>
  delete (uid: string): Promise<boolean>
  save (data: any): Promise<string>
}

@Service(tokens.CASH_SERVICE)
export default class CashServiceImpl implements CashService {
  async delete(uid: string): Promise<boolean> {
    return await api.delete(uid);
  }

  async getList(filter: any): Promise<any[]> {
    return await api.getList(filter);
  }

  async save(data: any): Promise<string> {
    return await api.save(data);
  }
}

export default class NavigationItem{
    name: string
    title: string
    icon: string
    path: string
    items : NavigationItem[]

  constructor(name: string, title: string, icon: string, path: string, items: NavigationItem[]) {
    this.name = name;
    this.title = title;
    this.icon = icon;
    this.path = path;
    this.items = items;
  }
}

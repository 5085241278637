import {Inject, Injectable} from "vue-typedi";
import tokens from "@/service/tokens";
import CashService from "@/service/cashService";
import {Action, Getter, Mutation, State} from "vuex-simple";

@Injectable()
export class Cashes {

  @Inject(tokens.CASH_SERVICE)
  public cashService: CashService

  @State()
  private items: any[];

  @Mutation()
  public mutate(nb: any[]) {
    this.items = nb;
  }

  @Mutation()
  public mutateAdd(nb: any) {
    this.items.push(nb);
  }

  @Action()
  public async load(): Promise<any>{
    const items = await this.cashService.getList({})
    this.mutate(items)
  }

  @Action()
  public async save(data: any) {
    const uid = await this.cashService.save(data)
    if(!data.uid){
      data.uid = uid
      this.items.push(data)
    }
  }

  @Action()
  public async delete(uid: string) {
    const res = await this.cashService.delete(uid)
    if(res){
      for(const i in this.items) {
        if(this.items[i].uid == uid) {
          this.items.splice(Number(i), 1)
          break
        }
      }
    }
  }

  @Getter()
  public get getList() {
    return this.items
  }

}

import fetch from "@/utils/fetch";
import {Pay} from "@/@type/pay.type";
import {DirectionFilter} from "@/@type/direction.filter";

export class DirectionApi {
  public async getPage(page: number = 0, filter: DirectionFilter) {
    const sortBy = filter.sortBy ? filter.sortBy : 'modifyDate'
    const sortType = filter.sortType ? filter.sortType : 'DESC'
    const sort = '&sort='+sortBy+','+sortType;

    return await fetch.post("/api/direction/page?page="+page+sort, filter)
  }

  public async getList(filter: DirectionFilter) {

    return await fetch.post("/api/direction/list", filter)
  }

  public async getListByPerson(personUid: string) {
    return await fetch.get("/api/direction/person/"+personUid+"/list")
  }


  public async getLogs(id: number = 0) {
    return await fetch.get("/api/direction/"+id+"/logs")
  }

  public async delete(id: string) {
    return await fetch.post("/api/direction/delete?id="+id, null)
  }

  public async cancel(id: string) {
    return await fetch.post("/api/direction/cancel?id="+id, null)
  }

  public async save(data: any) {
    return await fetch.post("/api/direction/save", data)
  }

  public async edit(data: any) {
    return await fetch.post("/api/direction/edit", data)
  }

  public async pay(data: Pay) {
    return await fetch.post("/api/direction/pay", data)
  }

  public async getCardTypes() {
    return await fetch.get("/api/direction/card-types")
  }
}

import Vue from "vue"
import VueRouter, { Route } from "vue-router"
import MiddlewarePlugin from "vue-router-middleware-plugin"
import routes from "./routes";
import session from "../utils/clientSession/session"

//need to hide
import {useStore} from 'vuex-simple';
import store from '@/store/index'
import {MyStore} from '@/store/store';
const myStore: MyStore = useStore(store);

const myMiddleware: any = MiddlewarePlugin

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

Vue.use(myMiddleware,  router);

router.beforeEach((to: Route, from: Route, next) => {
    myStore.app.init().then(() => {
        next();
    }).catch(() => { session.logout(location.origin) })
});

router.afterEach((to: Route)  => {
    Vue.nextTick(() => {
        document.title = to.meta && to.meta.label !== undefined ? to.meta.label + " - " + DEFAULT_TITLE : DEFAULT_TITLE;
    });
});

const DEFAULT_TITLE = "MedKassa";

export default router

import cookie from "@/utils/clientSession/cookie";
import Vue from "vue";
import catchErrors from "@/utils/catchErrors";
import session from "@/utils/clientSession/session";

const checkForError = function (response: any) {
    if (!response.ok) throw response;
    return response;
};

const processingResponse = function (response : any) {
    const header = response.headers.get("Content-Type");
    const disposition = response.headers.get("Content-Disposition")
    const type = header ? header.split(";")[0] : null;
    switch (type) {
        case "application/json":
            return response.json();
        case "text/plain":
            return response.text();
        case "application/doc":
            return new Promise(resolve => {
                response.blob().then(function (res: any) {
                    resolve({
                        filename: header.split(";")[1].split("=")[1],
                        blob: res
                    })
                });
            })
        case "application/pdf":
            return new Promise(resolve => {
                response.blob().then(function (res : any) {
                    resolve({
                        filename: header.split(";")[1].split("=")[1],
                        blob: res
                    })
                });
            })
        case "text/html":
          return new Promise(resolve => {
            response.blob().then(function (res : any) {
              resolve({
                filename: header.split(";")[1].split("=")[1],
                blob: res
              })
            });
          })
        case "application/octet-stream":
            return new Promise(resolve => {
                response.blob().then(function (res : any) {
                    resolve({
                        filename: disposition.split(";")[1].split("=")[1],
                        blob: res
                    })
                });
            })
        default:
            return null;
    }
};

const processingCatch = function (error: any) {
    if(error.status == 500 || error.status == 400){
      return error.json().then(function (err : any) {
        catchErrors(err)
      })
    }
    else if(error.status == 401){
      const err : any = {
        message : "unauthorized"
      }
      catchErrors(err)
      session.logout()
    }
    else if(error.status == 403){
      const err : any = {
        message : "forbidden"
      }
      catchErrors(err)
    }
    else if(error.status == undefined){
      const err : any = {
        message : error
      }
      return catchErrors(err)
    }

};

async function Get(path: string, params : any | undefined) {
    const apiUrl = Vue.prototype.$myStore.catalog.currentCatalog.host.url
    let request : any = undefined
    request = {
        method: "GET",
        mode: "cors",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + cookie.get('value'),
            Username: cookie.get('phone'),
            Catalog: Vue.prototype.$myStore.catalog.currentCatalog.catalog
        }
    };
    if (params && typeof(params) !== 'object' && params.length === undefined)
        return console.log("Error: URL params is not object. Please get Object an a property.");
    const urlProp = params ? "?" + new URLSearchParams(params) : undefined;
    return await fetch(urlProp ? `${apiUrl}${path}${urlProp}` : `${apiUrl}${path}`, request)
        .then(checkForError)
        .then(processingResponse)
        .catch(processingCatch);
}

async function Post(path: string, payload: any) {
    const apiUrl = Vue.prototype.$myStore.catalog.currentCatalog.host.url

    return await fetch(`${apiUrl}${path}`, {
        method: "POST",
        mode: "cors",
        credentials: 'include',
        body:
            payload === null
                ? null
                : typeof payload === "object"
                ? JSON.stringify(payload)
                : payload,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + cookie.get('value'),
            Username: cookie.get('phone'),
            Catalog: Vue.prototype.$myStore.catalog.currentCatalog.catalog
        }
    })
        .then(checkForError)
        .then(processingResponse)
        .catch(processingCatch);
}

async function Upload(path: string, file: File) {
  const apiUrl = Vue.prototype.$myStore.catalog.currentCatalog.host.url
  const formData = new FormData();
  formData.append("file", file);
  return await fetch(`${apiUrl}${path}`, {
    method: "POST",
    mode: "cors",
    body: formData,
    headers: {
      Authorization: "Bearer " + cookie.get('value'),
      Username: cookie.get('phone'),
      Catalog: Vue.prototype.$myStore.catalog.currentCatalog.catalog
    }
  })
    .then(checkForError)
    .then(processingResponse)
    .catch(processingCatch);
}

export default {
    get: async (path : string, params: any = undefined) => {
        return await Get(path, params);
    },
    post: async (path: string, payload : any) => {
        return await Post(path, payload);
    },
    postFile: async (path: string, file: File) => {
        return await Upload(path, file);
    },
    // getFile: async (path, file) => {
    //     return await GetFile(path, file);
    // }
};

import fetch from "@/utils/fetch";

export default class CashUserApi {

  public async save(data: any){
    return await fetch.post("/api/cash/user/save", data)
  }

  public async get(username: string) {
    return await fetch.get("/api/cash/user/get", {username: username})
  }

  public async delete(username: string) {
    return await fetch.post("/api/cash/user/delete?username="+username, null)
  }
}

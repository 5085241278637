
import {Component, Vue} from "vue-property-decorator";
import {Inject} from "vue-typedi";
import tokens from "@/service/tokens";
import ShiftService from "@/service/shiftService";
import {Shift} from "@/@type/shift.type";

@Component
export default class ShiftDialog extends Vue {
  dialog: boolean = false
  loading: boolean = false

  shift: Shift = {
    uid: null,
    cash: null,
    openDateTime: null,
    openUser: {
      uid: null,
      phone: null,
      fullName: null,
      email: null
    },
    closeDateTime: null,
    closeUser: {
      uid: null,
      phone: null,
      fullName: null,
      email: null
    }
  }


  @Inject(tokens.SHIFT_SERVICE)
  private shiftService: ShiftService

  async show(){
    this.shift = await this.shiftService.info();
    this.loading = false
    if(this.shift.openDateTime && this.shift.closeDateTime)
      this.loading = true
    this.dialog = true
  }

  async closeShift(){
    if(this.loading) return;
    this.loading = true
    try {
      this.shift = await this.shiftService.close();
    } finally {
      this.loading = false
    }
  }

  close(){
    this.dialog = false
  }
}

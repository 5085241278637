import CashUserApi from "@/service/api/cashUserApi";
import {Service} from "vue-typedi";
import tokens from "@/service/tokens";


const api = new CashUserApi();

export default interface CashUserService {
  get (username: string) : Promise<any>
  delete (username: string) : Promise<boolean>
  save(data: any): Promise<string>
}

@Service(tokens.CASH_USER_SERVICE)
export default class CashUserServiceImpl implements CashUserService {

  async save(data: any): Promise<string> {
    return await api.save(data)
  }

  async get(username: string): Promise<any> {
    return await api.get(username)
  }

  async delete(username: string): Promise<boolean> {
    return await api.delete(username)
  }

}

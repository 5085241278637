import {Action, Getter, Mutation, State} from 'vuex-simple';
import {Inject, Injectable} from "vue-typedi";
import tokens from "@/service/tokens";
import AccessesService from "@/service/accessesService";
import cookie from "@/utils/clientSession/cookie";
import SrcService from "@/service/srcService";

@Injectable()
export class Catalog {
    @Inject(tokens.ACCESSES_SERVICE)
    public accessesService: AccessesService

    @State()
    public currentCatalog: any;
    @State()
    public catalogs: any;
    @State()
    public accesses: any;

    @Mutation()
    public mutateCatalogs(nb: any) {
        this.catalogs = nb;
    }
    @Mutation()
    public mutateCurrentCatalog(nb: any) {
        this.currentCatalog = nb;
    }
    @Mutation()
    public mutateAccesses(nb: any) {
        this.accesses = nb;
    }

    @Action()
    public setCatalogs(nb : any): void{
        this.mutateCatalogs(nb)
    }

    @Action()
    public async setCurrentCatalog(nb : any): Promise<void> {
        this.mutateCurrentCatalog(nb)
        cookie.set('catalog', JSON.stringify(nb))
        this.mutateAccesses(await this.accessesService.getCurrentUserAccesses(this.currentCatalog))
    }

    @Getter()
    public get getAccesses() {
      return this.accesses
    }

    @Getter()
    public get getCurrentCatalog(){
      return this.currentCatalog
    }
}

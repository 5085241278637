export class Department {
  uid: string;
  title: string;
  deleted: boolean;
  children: Employee[];
  type: string;
}

export class DepartmentFilter {
  title: string;
  deleted: boolean;
}

export class Position {
  uid: string;
  title: string;
  deleted: boolean;
}

export class PositionFilter {
  uid: string;
  title: string;
  deleted: boolean;
}

export class Employee {
  uid: string;
  fullName: string;
  phone: string;
  email: string;
  incomeDirect: number;
  incomeIndirect: number;
  department: Department;
  departmentUid: string;
  position: Position;
  positionUid: string;
  deleted: boolean
  type: string;
}
